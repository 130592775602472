@import '../../../../index.scss';

.wrap {
    padding: 6px 10px;
}

.wrapBlock {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;

    .titleWrap{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.checkedUniversitiesWrap{
    background-color: $darkest80;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
    color: #fff;
    font-weight: 700;
    font-size: 10px;
    font-family: $fontFamilyInter;
}

.headerWrap{
    display: flex ;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 40px;
    @media screen and (max-width: 340px){
        padding-top: 30px;
    }
}

.modalWrap{
    padding: 0 25px;
}

.checkboxWrap{
    height: 540px;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    @media screen and (max-height: 860px){
        height: 450px;
    }

    @media screen and (max-height: 750px){
        height: 350px;
    }

    @media screen and (max-height: 660px){
        height: 300px;
    }

    @media screen and (max-height: 500px){
        height: 180px;
    }
}

.checkboxWrap::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
}

.checkboxWrap::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.btnWrap{
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding: 20px 0;
}

.inputWrap{
    width: 100%;
    margin: 30px 0;
    @media screen and (max-height: 730px){
        margin: 20px 0 ;
    }
}

.wrapM{
    display: none;
}

.wrapM.active{
    display: block;
}
