@import '../../../../index.scss';

.offersSavedWrap {
    background-color: $primary10;
    overflow-x: hidden;
    display: flex;
    flex-flow: column;
    height: 100vh;
    .offersWrap {
        padding: 0 20px;
    }
    .contentContainer {
        flex: 1 1 auto;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .title {
        margin-top: 24px;
        font-family: $fontFamilyMariupol;
        font-weight: 700;
        font-size: 40px;
        text-align: center;
        margin-bottom: 30px;

        @media screen and (max-width: 860px){
            font-size: 35px;
        }
        @media screen and (max-width: 670px){
            font-size: 30px;
        }

    }

    .message {
        font-family: $fontFamilyInter;
        font-size: 16px;
        margin-top: 15px;
        text-align: center;
        color: #000;
    }
}