@import '../../../../index.scss';

.inputWrap{
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 60px;
    margin-bottom: 15px;

    label{
        position: absolute;
        pointer-events: none;
        display: flex;
        color: $darkest60;
        align-items: center;
        top: 0;
        left: 20px;
        transition: 0.3s ease-in-out;
    }

    .inputScore, label{
        width: 100%;
        height: 100%;

    }

    .inputScore:valid
        + label {
            font-size: 10px;
            align-items: flex-start;
            pointer-events: none;
            margin-top: 5px;

        }
    .inputScore {
        border-radius: 12px;
        background-color: $darkest5;
        font-weight: 400;
        border: none;
        outline: none;
        margin-bottom: 15px;
        padding-left: 20px;
        transition: 0.3s ease-in-out;
        font-size: 16px;
        font-family: $fontFamilyInter;
    }

    .clearButton {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        transition: .4s;
        cursor: pointer;
    }

    .inputScore.error {
        border: 1px solid $secondaryPink100;
        background-color: #fce0e0;
        color: $secondaryPink100;
        transition: 0.2s ease-in-out;
    }

    .inputScore.error + label {
        color: $secondaryPink100;
    }

    .inputScore.error {
        ~ {
            .clearButton {
                filter: brightness(0.7);
            }
        }
    }
}
