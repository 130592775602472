@import '../../../../index.scss';

.inputCheckBox {
    opacity: 0;
    position: absolute;
}

.checkbox {
  padding: 2px;
  background-color: white;
  margin-right: 10px;
  position: relative;
  border: 1px solid #F59D33;
  border-radius: 7px;
  max-width: 26px;
  min-width: 26px;
  min-height: 26px;
  max-height: 26px;
}

.inputCheckBox:checked + .checkbox .mark {
  display: block;
}

.inputCheckBox:checked + .checkbox {
  background-color: $primary100;
}

.wrap{
  padding: 13px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}