@import '../../../../index.scss';

.table {
  background-color: #ffff;
  padding: 24px 14px 24px 24px;
  border-radius: 16px;
  max-width: 450px;

  .row {
    font-size: 14px;
    font-weight: 400;
    font-family: $fontFamilyInter;
    padding-bottom: 12px;
    &:nth-child(2), &:nth-child(1), &:first-child  {
      padding-top: 12px;
      border-bottom: 1px solid $darkest5;
    }

    &:last-child {
      border-top: 1px solid $darkest5;
    }

    &:last-child, &:nth-child(3) {
      padding-top: 12px;
    }

    display: flex;
    justify-content: space-between;

    .title {
      font-size: 14px;
      font-weight: 700;
      font-family: $fontFamilyRoboto;
      color: $primary100;
    }

  }

}
