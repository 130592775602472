@import '../../../../index.scss';

.wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 25px;
}

.wrapBlock:first-child {
    display: flex;
    align-items: center;
}

.wrapBlock .arrowLeftIcon {
    margin-right: 15px;
    cursor: pointer;
}

.clearButton {
    color: $darkest40;
    font-family: $fontFamilyInter;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
}

.clearButton:hover {
    color: $darkest60;
}
