@import '../../../../../index.scss';

.modal{
    height: 100vh;
    width: 100vw;
    background-color: rgba(157, 163, 167, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $fontFamilyInter ;
    pointer-events: none;
    opacity: 0;
    will-change: transform, opacity;
    transform: scale(1.15);
    z-index: 999;

    transition:
      transform 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946),
      opacity 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946);

    &.active {
        pointer-events: auto;
        opacity: 1;
        transform: scale(1);

        transition:
          transform 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946),
          opacity 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946);
    }

    .modalContent{
        z-index: -2;
        padding: 0 20px;
        border-radius: 24px;
        max-height: 470px;
        max-width: 320px;
        @media screen and (max-width: 380px) {
            max-height: 420px;
            max-width: 250px;
        }
        background-color: #fff;
        font-family: $fontFamilyMariupol;

        .headerWrap{
            margin-top: 40px;
            display: flex;
            justify-content: space-between;

            h1{
                @media screen and (max-width: 380px) {
                    font-size: 25px;

                }

            }
        }

        .descriptionWrap{
            margin-top: 20px;
            margin-bottom: 55px;
             @media screen and (max-height: 670px){
                margin-bottom: 35px;
            }
            width: 100%;
            p {
                font-size: 16px;
                font-weight: 400;
                font-style: normal;
                margin-bottom: 12px;
                @media screen and (max-width: 380px) {
                    font-size: 14px;
                }

                span{
                    font-weight: 700;
                }
            }
        }

        .btnWrap{
            width: 100%;
            margin-bottom: 25px;

            .btnZno{
                color: #fff;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                background: #0B1922;
                border-radius: 9px;
                padding: 20px 0;
                width: 100%;
                border: none;
                cursor: pointer;
                @media screen and (max-width: 380px) {
                    padding: 10px 30px;
                    font-size: 14px;
                }
            }
        }

    }
}