@import '../../../../index.scss';

.offerPageWrap {
    background-color: $primary10;
    min-height: 100vh;


    .contentWrap {
        padding: 0 25px;
        padding-top: 32px;
    }

    .coefficientWrap, .scoresTableWrap {
       padding-bottom: 50px;
    }

    .headerWrap {
        box-sizing: border-box;
        padding: 19px 25px;
        background-color: $primary20;

        .titleWrap {
            .name{
                font-family: $fontFamilyMariupol;
                margin-top: 20px;
                font-weight: 700;
                font-size: 24px;
            }

            .descWrap {
                margin-top: 10px;
                font-size: 14px;
                font-weight: 400;
                display: flex;
                align-items: center;
                font-family: $fontFamilyInter;
                color: $darkest80;
                @media screen and (max-width: 450px)  {
                    align-items: baseline;
                }

                .itemRow {
                    display: flex;
                    align-items: center;

                    .facultyNameWrap, .universityNameWrap {
                        display: flex;

                        .emoji {
                            width: 15px;
                            margin-right: 8px;
                        }
                    }

                    @media screen and (max-width: 450px) {
                        flex-direction: column;
                        align-items: baseline;
                    }
                }

                .firstItemRow {
                    div {
                        margin-right: 8px;
                    }
                }

                .dot {
                    @media screen and (max-width: 450px)  {
                        display: none;
                    }
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    background-color: $primary40;
                }

                @media screen and (min-width: 450px) {
                    .itemRow {
                        div {
                            margin-right: 8px;
                        }
                    }
                }

            }
        }
    }
}