@import '../../../../index.scss';

.inputCheckBox {
    opacity: 0;
    position: absolute;
}

.checkbox {
  padding: 2px;
  background-color: rgb(255, 255, 255);
  margin-right: 10px;
  position: relative;
  border: 1px solid $primary60;
  border-radius: 7px;
  max-width: 18px;
  min-width: 18px;
  min-height: 18px;
  max-height: 18px;
}

.inputCheckBox:checked + .checkbox .mark {
  display: block;
}

.inputCheckBox:checked + .checkbox {
  background-color: $primary100;
}

.wrap{
  padding: 16px 0;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  border-bottom: 1px solid $darkest20;
}

.region{
  font-size: 10px;
  font-weight: 400;
  font-family: $fontFamilyInter;
  color: $darkest40;
  margin-top: 5px;
}