@import '../../../../index.scss';

.showResultBtn{
  flex: 0 0 auto;

  border: none;
  outline: none;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $darkest80;
  padding: 20px;
  box-sizing: border-box;

  text-decoration: none;
  color: white !important;
  font-family: $fontFamilyInter;
  font-weight: 700;
  font-size: 16px;
  text-align: center;

  border-radius: 12px;

  cursor: pointer;

  transition:
    opacity 0.1s ease-in-out,
    transform 0.2s cubic-bezier(0.32, 0, 0.15, 1.75);
  &:hover {
    transform: scale(0.99);
  }
  &:active {
    transform: scale(0.95);
  }

  &:disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}