@import '../../../../index.scss';

.wrap {
    padding: 6px 10px;
}

.wrapBlock {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
}

.sortingWrap {
   margin-left: 30px;
   width: fit-content;
      @media screen and (max-width: 350px) {
            width: 280px;
        }
        @media screen and (max-width: 325px) {
            width: 230px;
        }
    .btnAddScoresWrap {
        .addScores{
            width: 80%;
            height: 40px;
            background-color: #fff;
            border: 1px solid $darkest80;
            border-radius: 8px;
            color: $darkest80;
            font-weight: 700;
            cursor: pointer;
            font-size: 13px;
        }
    }

    .scores {
        margin-bottom: 15px;
        min-height: auto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        font-family: $fontFamilyInter;
        color: $darkest80;
    }
}

