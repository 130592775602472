@import '../../../../index.scss';


.inputCheckBox {
  opacity: 0;
  position: absolute;
}

.checkbox {
  padding: 2px;
  background-color: rgb(255, 255, 255);
  max-width: 18px;
  max-height: 18px;
  min-height: 18px;
  min-width: 18px;
  position: relative;
  border: 1px solid #CED1D3;
  border-radius: 7px;
  margin-right: 10px;
}

.inputCheckBox:checked + .checkbox .mark {
  display: block;
}

.inputCheckBox:checked + .checkbox {
  background-color: #9DA3A7;
}

.description{
  color: $darkest40;
  font-size: 10px;
  font-family: $fontFamilyInter ;
}

.titleWrap{
  display: flex;
  align-items: center;
}
.emojiWrap{
  margin-right: 5px;
}

.descrWrap{
  margin-top: 5px;
}

.wrapWithoutDesc {
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
}

.wrapWithDesc {
  display: flex;
  padding: 10px 0;
  cursor: pointer;
}