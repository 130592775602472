@import '../../../../index.scss';

body {
    background-color: $darkest5;
}


.filtersWrap{
    padding-bottom: 100px;
    min-height: 100vh;
}

.btnWrap{
    padding: 20px 25px;
    position: fixed;
    bottom: 0;
    background-color: #fff;
    width: 100%;
    box-sizing: border-box;
}
