@import '../../../../index.scss';

.btnWrap {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }

    .btnDescr {
        margin-left: 5px;
        font-size: 14px;
        font-weight: 700;
        font-family: $fontFamilyInter;
        color: $primary100;
    }
}