@import '../../../../index.scss';


.inputCheckBox {
  opacity: 0;
  position: absolute;
  pointer-events: none;

}


.checkbox {
  padding: 2px;
  background-color: rgb(255, 255, 255);
  max-width: 18px;
  max-height: 18px;
  min-height: 18px;
  min-width: 18px;
  position: relative;
  border: 1px solid $primary100;
  border-radius: 50%;
  margin-right: 10px;
}

.checkbox.disable{
  opacity: 0.4;
}

.inputCheckBox:checked + .checkbox .mark {
  display: block;
}

.inputCheckBox:checked + .checkbox {
  background-color: $primary100;
}

.description{
  color: $darkest40;
  font-size: 10px;
  font-family: $fontFamilyInter ;
}

.titleWrap{
  display: flex;
  align-items: center;
}
.emojiWrap{
  margin-right: 5px;
}

.descrWrap {
  margin-top: 5px;
}

.descrWrap.option {
    @media screen and (min-width:530px){
      width: 50%;
  }
}

.wrap {
  display: flex;
  padding: 16px 0;
  cursor: pointer;
}
