@import '../../../../index.scss';
.wrap{
    display: flex;

    .arrowBack{
        margin-right: 10px;

        svg {
            path {
                stroke: #000;
            }
        }
    }

    .stepTitle{
        color: #000;
        font-size: 14px;
        font-weight: 700;
        font-family: $fontFamilyInter;
    }
}