@import '../../../../index.scss';

@mixin textBold {
    font-weight: 700;
    color: $darkest100;
    font-family: $fontFamilyMariupol;
    font-size: 18px;
}

.offerCard{
    background-color: #fff;
    border-radius: 16px;
    padding: 20px;
    margin-top: 10px;

    .titleWrap{
        display: flex;
        justify-content: space-between;

        .titleName{
            display: flex;
            align-items: flex-end;
            @include textBold;
            cursor: pointer;

            @media screen and (max-width: 750px) {
                flex-direction: column;
                align-items: baseline;
            }

            .universityNameWrap {
                display: flex;
                align-items: flex-end;

                .cardId {
                    margin-right: 5px;
                }
            }
        }

    }

    .descrWrap {
        margin-top: 18px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        font-family: $fontFamilyInter;

        .name {
            color: $darkest100;
        }

        .descr {
            color: $darkest60;
            margin-top: 3px;
        }
    }

    .znoScoreWrap {
        background: #EFFEF1;
        margin-top: 16px;
        border: 1px dashed #D8DFDA;
        border-radius: 9px;
        padding: 13px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        &.green {
            background-color: rgba(239, 254, 241, 1);
        }

        &.red {
            background-color:rgba(255, 240, 240, 1);
        }

        &.yellow {
            background-color: rgba(255, 253, 215, 1);
        }

        &.grey {
            background-color: $darkest5;
        }

        .znoScore {
            @include textBold;
            margin-right: 20px;
        }

        .scoreDescr {
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            color: $darkest80;
            font-family: $fontFamilyInter;

            span {
                font-weight: 700;
            }

        }
    }

    .mark {
        cursor: pointer;
    }
}

.znoScoreWrap p:has(p.green) {
    background-color: red;

   }