@import '../../../../index.scss';

.bullet {
    @media screen and (max-width: 450px)  {
        display: none;
    }
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: $primary40;
}