@import '../../../../index.scss';

.modal{
    min-height: 100vh;
    width: 100vw;
    background-color: rgba(157, 163, 167, 0.7);
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    pointer-events: none;
    opacity: 0;
    z-index: 100;
    will-change: transform, opacity;
    transform: scale(1.15);

    transition:
      transform 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946),
      opacity 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946);

    &.active {
        pointer-events: auto;
        opacity: 1;
        transform: scale(1);

        transition:
          transform 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946),
          opacity 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946);
    }

    .modalContent{
        width: 100%;
        background-color: #fff;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        z-index: 200;
        font-family: $fontFamilyMariupol;
    }
}


