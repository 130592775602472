@import '../../../../index.scss';

.table {
    padding: 24px;
    background-color: #fff;
    border-radius: 16px;
    max-width: 450px;
    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 12px;
        border-bottom: 1px solid $darkest5;
        font-family: $fontFamilyRoboto;
        color: $darkest100;
        font-size: 14px;
        font-weight: 400;
        padding-top: 16px;

        div {
            width: 90px;
            text-align: center;
        }

    }

    .titleRow {
        padding-top: 0;
        padding-bottom: 16px;
        font-weight: 600;
        font-size: 14px;
        font-family: $fontFamilyInter;
        color: $primary100;
    }

}
