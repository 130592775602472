@import '../../../../index.scss';

.offersResultWrap {
    background-color: $primary10;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100vh;

    .chipsWrap {
        background: #FDF0E0;
        padding: 20px;
    }

    .cardsWrap{
        margin-top: 24px;
        padding: 0 20px;
        margin-bottom: 25px;
        flex:1 0 auto;
        overflow: hidden;
    }

    .message {
        font-family: $fontFamilyInter;
        font-size: 16px;
        margin-top: 15px;
        text-align: center;
        color: #000;
    }
}

.virtWrap {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

.virtWrap::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
}

.virtWrap::-webkit-scrollbar-thumb {
    background-color: transparent;
}




