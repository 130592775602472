@import '../../../../index.scss';

.inputWrap{
    display: inline-block;
    position: relative;
    width: 100%;

    svg {
        position: absolute;
        left: auto;
        right: 10px;
        top: 7px;
    }

    .inputField {
        box-sizing: border-box;
        width: 100%;
        padding: 10px;
        outline: none;
        border: 1px solid $darkest20;
        border-radius: 6px;

        &::placeholder{
            font-size: 14px;
            font-weight: 400;
            color: $darkest40;
        }
    }
}