@import '../../../../index.scss';

.offerDetailsWrap {
    background-color: #ffff;
    padding: 24px;
    border-radius: 16px;
    margin-bottom: 48px;

    .itemWrap{
        padding-bottom: 24px;

        .itemTitle {
            font-weight: 700;
            font-size: 10px;
            color: $darkest60;
            font-family: $fontFamilyRoboto ;
            padding-bottom: 4px;
            text-transform: uppercase;

        }

        .offerDescr {
            font-weight: 500;
            font-size: 16px;
            color: $darkest100;
            font-family: $fontFamilyInter;
        }
    }

    .itemWrap:last-child {
        padding-bottom: 0;
    }
}