@import '../../../../index.scss';

.headerWrap{
    background-color: $darkest5;
    padding: 25px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    @media screen and (max-height: 670px){
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .stepHeaderWrap{
        margin-bottom: 25px;
         @media screen and (max-height: 670px){
            margin-bottom: 15px;
        }


    }
}