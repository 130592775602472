@import '../../../../index.scss';

.wrap{

    .childrenWrap{
        margin-top: 50px;
        height: 500px;
        overflow-y: auto;
        padding: 0 25px;
        @media screen and (max-height: 860px){
            height: 450px;
        }

        @media screen and (max-height: 750px){
            height: 340px;
        }

        @media screen and (max-height: 670px){
            height: 300px
        }

        @media screen and (max-height: 500px){
            height: 170px;
            margin-top: 30px;
        }
    }

    .btnWrap{
        padding: 25px;
    }
}