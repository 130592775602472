@import '../../../../index.scss';

.title {
    font-family: $fontFamilyMariupol;
    font-style: normal;
    padding: 0;
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    color: $darkest100;
    cursor: pointer;
    @media screen and (max-width: 340px){
        font-size: 20px;
    }
}