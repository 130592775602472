@import '../../../../index.scss';

.wrap {
    padding: 6px 10px;
}

.wrapCard {
    border-radius: 10px;
    padding: 15px 20px;
    background-color: $white;
}

.wrapBlock {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
}

.wrapFilter {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
}

.wrapFilter.closed{
    border-bottom: 1px solid $darkest20;
}

.extendedFilterTitle {
    display: flex;
    align-items: center;
}

.extendedFilterTitle span {
    margin-right: 10px;
}

.wrapExtendedFilterBlock:first-child {
    margin-right: 10px;
}

.clearButton {
    color: $darkest40;
    font-family: $fontFamilyInter;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
}

.clearButton:hover {
    color: $darkest60;
}


.showMore{
    font-size: 14px;
    font-weight: 700;
    font-family: $fontFamilyInter;
    margin-top: 15px;
    cursor: pointer;
}


